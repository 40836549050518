<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link
      to="/"
      class="brand-logo"
    >
      <b-img
        :src="appLogoFull"
        height="40"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('pageNotFound') }} 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          {{ $t('oops') }} 😖 {{ $t('notFoundRequest') }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          {{ $t('backToHome') }}
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BButton, BImg,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import store from '@/store/index'

import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  setup() {
    const { appLogoFull: defaultLogo } = $themeConfig.app
    const appLogoFull = computed(() => store.getters['app/getLogo']).value || defaultLogo
    return {
      appLogoFull,
    }
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
